import { render, staticRenderFns } from "./list.vue?vue&type=template&id=12a4c83d&"
import script from "./list.vue?vue&type=script&lang=js&"
export * from "./list.vue?vue&type=script&lang=js&"
import style0 from "./list.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VFlex,VIcon,VLayout,VPagination,VSimpleTable,VSnackbar,VTextField})
